@mixin arrow($color) {
  border: 1px solid $color;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.5em;
  pointer-events: none;
  position: absolute;
  transform: rotate(-45deg);
  width: 0.5em;
}

@mixin block {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

@mixin clearfix {
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}

@mixin center($width, $height: 0) {
  position: absolute;

  @if $height != 0 {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$height} / 2));
  }
  @else {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$width} / 2));
  }
}

@mixin delete {
  @include unselectable;

  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba($black, 0.2);
  border: none;
  border-radius: 290486px;
  cursor: pointer;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: $size-normal;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;

  &:before,
  &:after {
    background-color: $white;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }

  &:before {
    height: 2px;
    width: 50%;
  }

  &:after {
    height: 50%;
    width: 2px;
  }

  &:hover,
  &:focus {
    background-color: rgba($black, 0.3);
  }

  &:active {
    background-color: rgba($black, 0.4);
  }

  // Sizes
  &.is-small {
    height: 16px;
    max-height: 16px;
    max-width: 16px;
    min-height: 16px;
    min-width: 16px;
    width: 16px;
  }

  &.is-medium {
    height: 24px;
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    width: 24px;
  }

  &.is-large {
    height: 32px;
    max-height: 32px;
    max-width: 32px;
    min-height: 32px;
    min-width: 32px;
    width: 32px;
  }
}

@mixin fa($size, $dimensions) {
  display: inline-block;
  font-size: $size;
  height: $dimensions;
  line-height: $dimensions;
  text-align: center;
  vertical-align: top;
  width: $dimensions;
}

@mixin hamburger($dimensions) {
  cursor: pointer;
  display: block;
  height: $dimensions;
  position: relative;
  width: $dimensions;

  span {
    background-color: currentColor;
    display: block;
    height: 1px;
    left: 50%;
    margin-left: -7px;
    position: absolute;
    top: 50%;
    transition: none $speed $easing;
    transition-property: background, left, opacity, transform;
    width: 15px;

    &:nth-child(1) {
      margin-top: -6px;
    }

    &:nth-child(2) {
      margin-top: -1px;
    }

    &:nth-child(3) {
      margin-top: 4px;
    }
  }

  &:hover {
    background-color: rgba(black, 0.05);
  }

  // Modifers
  &.is-active {
    span {
      &:nth-child(1) {
        margin-left: -5px;
        transform: rotate(45deg);
        transform-origin: left top;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        margin-left: -5px;
        transform: rotate(-45deg);
        transform-origin: left bottom;
      }
    }
  }
}

@mixin loader {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $border;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

@mixin overflow-touch {
  -webkit-overflow-scrolling: touch;
}

@mixin overlay($offset: 0) {
  bottom: $offset;
  left: $offset;
  position: absolute;
  right: $offset;
  top: $offset;
}

@mixin placeholder {
  $placeholders: ":-moz" ":-webkit-input" "-moz" "-ms-input";

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Responsiveness

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin fullhd {
  @media screen and (min-width: $fullhd) {
    @content;
  }
}
