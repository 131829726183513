.button {
  border-radius: 0;
  text-transform: uppercase;
  font-size: 24px;
  padding: 0.9em 2em;
  letter-spacing: 1px;
  font-weight: 600;
  height: auto;
  line-height: 29px;
  display: inline-flex;
  border: 2px solid $orange;


  .orange-arrow,
  .white-arrow,
  .arrow-icon {
    width: 28px;

    margin-left: 0.5em;
  }

  .arrow-icon {
    stroke: $orange;
    width: 28px;
    height: 22px;
    transition: all 300ms;
  }

  .white-arrow {
    display: block;
  }

  .orange-arrow {
    display: none;
  }

  &:hover {
    border-color: $orange;
    color: $orange;
    background: $white;

    .orange-arrow {
      display: block;
    }

    .white-arrow {
      display: none;
    }
  }

  &.ghost {
    border: 2px solid $orange;
    background: rgba(0, 0, 0, 0);

    font-size: 18px;
    padding: 0.75em 1.5em;

    color: $orange;

    .orange-arrow,
    .teal-arrow,
    .white-arrow {
      width: 24px;
      margin-left: 0.5em;
    }

    .teal-arrow {
      display: block;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: $orange;
      color: $white;
      background: $orange;

      .white-arrow {
        display: block;
      }

      .teal-arrow {
        display: none;
      }
    }
  }
}
