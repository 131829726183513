.section-2 {
  padding-top: 3rem;
  padding-bottom: 0;
  margin-bottom: -3rem;

  @include desktop {
    margin-bottom: -3rem;
  }

  @include tablet {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .parallaxed {
    @include tablet {
      position: absolute;
      right: 0;
    }
  }

  h2 {
    text-align: left;
  }
  p {
    text-align: left;
  }

  .computer-lock-icon {
    background-image: url(/assets/images/computer-lock-icon.svg);
    width: 175px;
    height: 180px;
    background-repeat: no-repeat;
    position: absolute;
    top: -1em;
    right: 0;
  }

  .video-float {
    z-index: 1;
    position: relative;

    @include tablet {
      position: absolute;
    }
  }

  #video-slide {

    @include tablet {
      margin-top: -12rem;
    }

    @include desktop {
      margin-top: -12rem;
    }

  }
  .video-box {
    .video-info {
        background-color: #EEEEEE;
        padding: 1em 1em 1em;

        @include tablet {
          padding: 2em 3em 1em;
        }
    }
  }
}
