.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.text-bold {
  font-weight: 700;
}
.pb0 {
  padding-bottom: 0;
}
.mt6 {
  margin-top: 6em;
}

.teal-border {
  border-top: 8px solid $teal;
}

.orange-border {
  border-top: 8px solid $orange;
}

.teal-border--bottom {
  border-bottom: 8px solid $teal;
}

.purple-border {
  border-top: 8px solid $purple;
}

[data-reveal] {
  visibility: hidden;
}
