.section-5 {
  padding: 6rem 5px 0;

  .columns {
    margin-bottom: 0;
  }


  @include tablet {
  }

  @include desktop {
    margin-right: $gap;
    padding: 5px 0 6rem 0;
  }

  h2 {
    text-align: left;
  }

  p {
    font-size: 20px;
    line-height: 32px;
    text-align: left;
  }


  .section-container {
    @include desktop {
      padding-left: $gap;
    }
  }

  .container {
    @include desktop {

    }
  }

  .making-money {

    background-image: url(/assets/images/meeting.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    // background-size: 120%;
    background-attachment: fixed;
    background-position: top center;
    margin-top: -250px;
    height: 500px;
    padding: 0;

    &::after {
      background: linear-gradient(180deg, rgba(2, 38, 64, 0.5) 0%, #022640 100%);
      width: 100%;
      height: 100%;
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    .inner {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: flex-end;
      height: 100%;
      padding: 0 2em 2em;
      p {
        color: $white;
      }

    }
  }

  .good-guys {
    display: none;

    .handshake-icon {
      background-image: url(/assets/images/handshake-icon.svg);
      width: 220px;
      height: 200px;
      background-repeat: no-repeat;
      position: absolute;
      top: -1.5em;
      right: 0;
    }


    @include desktop {
      display: block;
    }
    .inner {
      padding: 0 2em 0em;
    }
  }




  .style-block {
    display: none;
    background-image: url(/assets/images/computers.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border-top: 8px solid $teal;
    border-bottom: 8px solid $purple;
    height: 400px;
    width: 500px;
    position: absolute;
    right: -4em;
    top: 0;

    @include desktop {
      display: block;
    }

    &::after {
      background: linear-gradient(180deg, rgba(2, 38, 64, 0.5) 0%, #007FC1 100%);
      width: 100%;
      height: 100%;
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
