.section-3 {
  background: $blue-gradient $dark-blue;

  padding-bottom: 3rem;

  @include tablet {
    padding-bottom: 6rem;
  }

  .mission-icon {
    background-image: url(/assets/images/mission-icon.svg);
    width: 180px;
    height: 175px;
    background-repeat: no-repeat;
    position: absolute;
    top: -1.5em;
    right: 0;
  }

  p {
    color: $white;
  }

  h2 {
    text-align: left;
  }

  p {
    text-align: left;
  }

}
