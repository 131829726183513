html {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
  max-width: 100%;
  width: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
