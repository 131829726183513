// $image-height: 362px;
// $card-width: 320px;
$card-height: 380px;
$transition-time: 500ms;

@mixin cardHover {

    .cover {
      &::after {
        opacity: 0;
      }
      .title {
        top: calc(6rem + 8px);
        position: absolute;
        color: $dark-blue;
      }
      .card-icon {
        opacity: 0;
      }
      .button {
        transform: translateY(0) translateX(-50%);
        bottom: 3em;
        left: 50%;
        background: $white;
        transition: transform $transition-time 200ms, background $transition-time 0ms, color $transition-time 0ms;
        &:hover {
          background: $orange;
          .arrow-icon {
            stroke: $white;
          }
        }
      }
    }
    .content {
      transform: translateY(8px);
    }
}


.section-4 {
  background-repeat: no-repeat;
  position: relative;

  h2 {
    text-align: left;
  }

  p {
    text-align: left;
  }

  .section {
    padding-bottom: 2em;
    padding-top: 12em;

    @media (min-width: 400px) {
      padding-top: 15em;
    }

    @media (min-width: 500px) {
      padding-top: 18em;
    }

    @media (min-width: 600px) {
      padding-top: 20em;
    }

    @media (min-width: 700px) {
      padding-top: 22em;
    }

    @include tablet {
      padding-top: 3em;
    }



    // @include tablet {
    //   margin-right: $gap;
    // }

  }

  // .course-offerings {
  //   padding-top: 0em;

  //   @include tablet {
  //     padding-top: 12em;
  //   }
  // }

  .image-container {
    width: 100%;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;

    @include tablet {
      background-size: cover;
      background-image: url(/assets/images/students-gathered.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-attachment: fixed;
      bottom: 0;
      width: 50%;
    }

    img {
      @include tablet {
        display: none;
      }
    }

    .overlay {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(180deg, rgba(2, 38, 64, 0) 0%, $white 90%);

      @include tablet {
        height: 100%;
        background-image: linear-gradient(90deg, rgba(2,38,64,0) 50%, $white 99%);
      }
    }
  }
}


.section.filter-items-container {
  background: $lightest-gray;
  padding-bottom: 12em;
  padding-top: 2rem;
  overflow: hidden;

  @include tablet {
    padding-top: 6rem;
  }

  @include desktop {
    padding-bottom: 24em;
  }

  .card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 1;

    .card-item {
      width: 100%;
      opacity: 1;
      perspective: 1500px;
      margin: 1em 0;

      &.hide {
        opacity: 0;
        display: none;
      }

      @include tablet {
        width: 50%;
      }

      @include desktop {
        width: 33.33%;
        max-width: 33.33%;
      }

      .card {
        margin: 1em;
        display: flex;
        flex-direction: column;
        position: relative;
        box-shadow: none;
        cursor: default;
        height: 100%;
        min-height: $card-height;
        overflow: hidden;

        .cover {
          background: $purple;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background $transition-time ;
          padding-bottom: 3em;
          // padding: 12rem 0;

          &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: $purple-gradient;
            opacity: 0.7;
            transition: all $transition-time;
          }

          .button {
            z-index: 2;
            position: relative;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateY(200%) translateX(-50%);
            transition: all $transition-time;
            background: $white;
            font-weight: bold;

            .arrow-icon {
              width: 22px;
              height: 18px;
              stroke: $blue;
              transition: all $transition-time;
            }
          }

          .card-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-repeat: no-repeat;
            opacity: 1;
            transition: all $transition-time;

            &.phone-lock-icon {
              background-image: url(/assets/images/phone-lock-icon.svg);
              width: 125px;
              height: 215px;
            }
            &.computer-icon {
              background-image: url(/assets/images/computer-icon.svg);
              width: 200px;
              height: 196px;
            }
            &.cloud-icon {
              background-image: url(/assets/images/cloud-icon.svg);
              width: 196px;
              height: 162px;
            }
          }

          .title {
            color: $white;
            transition: all $transition-time;
            position: absolute;
            top: 50%;
          }
        }

        .content {
          position: relative;
          height: 100%;
          transform: translateY(100%);
          padding: 6rem 3em 6em;
          font-size: 14px;
          transition: transform $transition-time;
          opacity: 1;
          background: $white;

          p {
            line-height: 1.50em;
            text-align: center;
            margin-bottom: 1em;
            font-size: 16px;
          }

          .title {
            padding: 0 1em 0.5em;
            position: relative;
            opacity: 0;
          }
        }

        .title {
          text-align: center;
          font-weight: 700;
          font-size: 26px;
          line-height: 22px;

          &.title-absolute {
            padding: 0 1em 1em;
            color: $white;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            z-index: 3;
          }
        }
      }
    }
  }
}

.can-touch .section.filter-items-container .card-container .card-item .card.hover {
  @include cardHover;
}

.no-touch .section.filter-items-container .card-container .card-item .card:hover {
  @include cardHover;
}

.matrix-item {
  position: absolute;
  // opacity: 0.25 !important;
  color: #CCC;
  display: block;
  font-weight: bold;
}
