$logo-height: 96px;

.navbar-container {
  width: 100%;
  z-index: 9999;
}
.container > .navbar {

  display: flex;
  justify-content: center;

  @include tablet {
    margin: 1em 2em;
    justify-content: flex-start;
  }

  @include desktop {
    margin: 1em 0;
  }
  align-items: center;

  .separator {
    height: $logo-height;
    background: white;
    width: 1px;
    margin: 1em 2em;
  }

  .navbar-brand {
    margin-left: 0;

    img {
      height: $logo-height;
      width: $logo-height;
    }

    .university {
      position: absolute;
      bottom: 0;
      text-transform: uppercase;
      color: #fff;
      transform: translateY(200%) translateX(-50%);
    }
  }
}
