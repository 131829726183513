$borderThickness: 8px;

.fancy-border {
  position: absolute;
  z-index: 1;

  &.top,
  &.bottom {
    height: $borderThickness;
    width: 100%;
  }

  &.top {
    top: -8px;
    // &::before {
    //   content: ' ';
    //   display: block;
    //   width: 0;
    //   height: 0;
    //   border-top: $borderThickness solid blue;
    //   border-bottom: $borderThickness solid transparent;
    //   border-left: $borderThickness solid transparent;
    //   position: absolute;
    //   left: -$borderThickness;
    // }
  }

  &.bottom {
    bottom: -8px;
  }

  &.left,
  &.right {
    width: $borderThickness;
    height: 100%;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  // &.left + &.top {
  //   width: calc(100% + #{$borderThickness});
  // }

  // &.top + &.left {
  //   height: calc(100% + #{$borderThickness});
  //   top: -$borderThickness;
  // }

  // &.top + &.right {
  //   height: calc(100% + #{$borderThickness});
  //   top: -$borderThickness;
  // }

  // &.right + &.bottom {
  //   width: calc(100% + #{$borderThickness});
  //   right: -$borderThickness;
  // }

}
