.section-1 {

  padding-top: calc(#{$navbar-height} + #{$section-padding-v});
  padding-bottom: calc(#{$navbar-height} + 2rem);
  position: relative;
  text-align: center;

  @include tablet {
    text-align: left;
  }

  .get-started {
    padding-right: 2em;
    background-color: $orange;
    display: inline-flex;
    letter-spacing: 3px;
    margin: 1em 0;


    .arrow-icon {
      stroke: $white;
    }

    &:hover {
      background: $white;
      .arrow-icon {
        stroke: $orange;
      }
    }
  }


  .left,.right {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .left {

    @include tablet {
      background: $purple;
      left: 0;
      right: 69%;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $purple-gradient;
        opacity: 0.3;
      }

      .computer-shield {
        background-image: url(/assets/images/computer-shield-icon.svg);
        background-repeat: no-repeat;
        background-position: 100% 50%;
        right: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  .right {
    background-image: url(/assets/images/hero-banner.jpg);
    background-position: 0%;
    background-size: cover;
    left: 0;
    right: 0;

    @include tablet {
      left: 30%;
    }


    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: $blue-gradient-reverse;
      opacity: 0.3;

      @include tablet {
        background: transparent;
      }
    }
  }

  h1 {
    span {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.15em;
      display: block;
      padding-top: 0.5em;

      @include tablet {
        font-size: 36px;
      }
    }
  }
}
