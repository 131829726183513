
$dark-blue: #022640;
$dark-blue1: #032741;
$light-blue: #007FC1;
$purple: #A07DF3;
$purple-gradient: linear-gradient(180deg, rgba(2, 38, 64, 0) 0%, #022640 100%);
$blue-gradient: linear-gradient(0deg, rgba(2, 38, 64, 0) 0%, #151D23 100%);
$blue-gradient-reverse: linear-gradient(180deg, rgba(2, 38, 64, 0) 0%, #151D23 100%);

$teal: #72EEB2;
$white: #FFF;
$lightest-gray: #E8E8E8;
$gray-lightest: $lightest-gray;
$light-gray: #D8D8D8;
$gray-light: $light-gray;
$gray: #909090;
$orange: #F16A3D;
$black: #000000;


$container-width: 1440px;
$column-gap: 5px;
$gap: 80px;

$container-offset: 100px;

$tablet: 769px;
$desktop: 960px + 2 * $gap;
$widescreen: 1152px + 2 * $gap;
$fullhd: $container-width;

$proxima: 'proxima-nova';

$button-background-color: $orange;
$button-border-color: $orange;
$button-color: $white;

$navbar-height: 80px;
$navbar-item-color: #fff;
$navbar-background-color: transparent;

$section-padding-h: 1.5rem;
$section-padding-v: 6rem;
$section-padding: $section-padding-v $section-padding-h;

$carousel-max-width: 842px;

// $navbar-item-color: $grey-dark !default;
// $navbar-item-hover-color: $black !default;
// $navbar-item-hover-background-color: $background !default;
// $navbar-item-active-color: $black !default;
// $navbar-item-active-background-color: transparent !default;
// $navbar-item-img-max-height: 1.75rem !default;

// $navbar-tab-hover-background-color: transparent !default;
// $navbar-tab-hover-border-bottom-color: $primary !default;
// $navbar-tab-active-color: $primary !default;
// $navbar-tab-active-background-color: transparent !default;
// $navbar-tab-active-border-bottom-color: $primary !default;
// $navbar-tab-active-border-bottom-style: solid !default;
// $navbar-tab-active-border-bottom-width: 3px !default;

// $navbar-dropdown-background-color: $white !default;
// $navbar-dropdown-border-top: 1px solid $border !default;
// $navbar-dropdown-offset: -4px !default;
// $navbar-dropdown-arrow: $link !default;
// $navbar-dropdown-radius: $radius-large !default;
// $navbar-dropdown-z: 20 !default;

// $navbar-dropdown-boxed-radius: $radius-large !default;
// $navbar-dropdown-boxed-shadow: 0 8px 8px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;

// $navbar-dropdown-item-hover-color: $black !default;
// $navbar-dropdown-item-hover-background-color: $background !default;
// $navbar-dropdown-item-active-color: $primary !default;
// $navbar-dropdown-item-active-background-color: $background !default;

// $navbar-divider-background-color: $border !default;
