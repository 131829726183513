.video {
  position: relative;

  .video-play-icon {
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.25);
    background-repeat: no-repeat;
    position: absolute;
    opacity: 1;
    transition: opacity 300ms;
    bottom: 32px;

    &:after {
      background-image: url(../images/play-button.svg);
      width: 124px;
      height: 124px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      content: ' ';
      display: block;
      position: absolute;
    }

    &.fade {
      opacity: 0;
    }
  }

  video {
    display: block;
    object-fit: inherit
  }
}

.video-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  opacity: 1;
  background-image: linear-gradient(to-bottom, rgb(3, 113, 168) 13%, rgb(0, 136, 204) 100%);
}

.video-controls {
  .play-button {
    width: 36px;
  }

  .mute-button {
    display: none;
  }

  button {
    background: rgba(0, 0, 0, .5);
    border: 0;
    color: #EEE;
    border-radius: 3px;
  }

  button:hover {
    cursor: pointer;
  }

  .seek-bar {
    width: calc(100% - 201px);
    display: inline-block;
  }

  .volume-bar {
    width: 60px;
    margin-left: 10px;
  }
}
.video:hover .video-controls {
  opacity: 0.95;
}

